import request from "@/uitils/request";
// 获取单个问卷信息
let findSurveyInfo = (data) => request('/survey/findSurveyInfo', 'post', data);
// 预览问卷
let getPreviewSurvey = (data) => request(`/survey/getPreviewSurvey`, 'post', data);
// 发布/停止问卷
let updateSurveyStatus = (data) => request('/survey/updateSurveyStatus', 'post', data);
// 修改问卷名称及说明
let updateSurveyNameOrExplains = (data) => request('/survey/updateSurveyNameOrExplains', 'post', data);
// 修改问卷结束文案
let updateEndContent = (data) => request('/survey/updateEndContent', 'post', data);
// 修改问卷时间控制
let updateTime = (data) => request('/survey/updateTime', 'post', data);
// 修改问卷限制条件
let updateSurveyConfine = (data) => request('/survey/updateSurveyConfine', 'post', data);
// 发送问卷
let getLink = (data) => request('/survey/getLink', 'post', data);
// 修改问卷考试密码
let updatePassword = (data) => request('/survey/updatePassword', 'post', data);
// 修改问卷题目展示类型
let updateTopicShow = (data) => request('/survey/updateTopicShow', 'post', data);
// 登陆方式
let  updateSurveyLoginType =(data)=>request('/survey/updateSurveyLoginType','post',data)
export {
    findSurveyInfo, getPreviewSurvey, updateSurveyConfine, updateSurveyStatus, updateSurveyNameOrExplains, updateEndContent, updateTime, getLink, updatePassword, updateTopicShow,updateSurveyLoginType
}