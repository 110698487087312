import Vue from "vue";
import Vuex from "vuex";
import { findSurveyInfo } from "@/api/questionnaire";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    questuonnaireInfo: null, //问卷信息
    surrey: [],
    isLoginPage: false,
  },
  getters: {},
  mutations: {
    // 禁止导航重复
    changeLoginState(state, boolean) {
      state.isLoginPage = boolean;
    },
    // 获取问卷信息
    setQuestion(state, con) {
      state.questuonnaireInfo = con;
    },
    // 获取问卷列表
    setFindSurrey(state, list) {
      state.surrey = list;
    },
  },
  actions: {
    // 获取问卷信息
    async findSurveyInfo({ commit }, id) {
      let res = await findSurveyInfo({
        id: id,
      });
      if (res.data.code == 200) {
        commit("setQuestion", res.data.data); //在这里提交的名字一般大写
        console.log(res.data.data, "res.da");
      }
    },
  },
  modules: {},
});
