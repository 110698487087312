import axios from "axios";
import router from "../router";
import cookies from "vue-cookies";
import store from "../store";
import baseURL from "@/api/config";
axios.defaults.baseURL = baseURL; //测试
axios.interceptors.request.use((config) => {
  // config.headers['Authorization'] = localStorage.getItem('token');
  // config.headers['member'] = localStorage.getItem('member');
  if (!config.headers["Authorization"]) {
    config.headers["Authorization"] = cookies.get("token");
  }
  config.headers["member"] = cookies.get("member");
  return config;
});
// 响应拦截处理无效token
axios.interceptors.response.use((res) => {
  if (res.data.code == -1) {
    // 表示token过期
    // localStorage.removeItem("token");
    // localStorage.removeItem("member");
    sessionStorage.removeItem("userinfo");
    cookies.remove("token");
    cookies.remove("member");
    // router.push({
    //     path: "/login"
    // })
    if (!store.state.isLoginPage) {
      router.push({
        path: "/login",
      });
      store.commit("changeLoginState", true); // 调用mutation
    }
    return res;
  }

  return res;
});
export default function request(url, methods, params) {
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      method: methods,
      // data:params,
      params: methods === "get" ? params : null,
      data: methods === "post" || "delete" || "put" ? params : null,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        // reject(error)
        if (error.response) {
          // 服务器返回一个状态码超出2xx范围的响应
          console.error("Error response:", error.response);
        } else if (error.request) {
          // 请求已经发出，但没有收到响应
          console.error("No response received:", error.request);
        } else {
          // 设置请求时发生了一些错误
          console.error("Request setup error:", error.message);
        }

        console.error("Full error object:", error);
      });
  });
}
